import { graphql, useStaticQuery } from "gatsby";

const useSanityGlobals = () => {
  const { sanityGlobals } = useStaticQuery(graphql`
    query Globals {
      sanityGlobals {
        id

        cookiesMessage
        newsletterMessage

        footerLinks {
          text
          url
        }
        footerNote
        headerLinks {
          text
          url
        }
      }
    }
  `);

  return sanityGlobals;
};

export default useSanityGlobals;
