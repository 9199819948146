import { graphql, useStaticQuery } from "gatsby";

const useFAQs = () => {
  const { allSanityFaq } = useStaticQuery(graphql`
    query FAQs {
      allSanityFaq {
        edges {
          node {
            backgroundColor {
              hex
              title
            }
            fontColor {
              hex
              title
            }

            heading

            category {
              title
            }

            questions {
              question
              answer
            }

            image {
              asset {
                gatsbyImageData(
                  width: 2048
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            imageAlignment
          }
        }
      }
    }
  `);

  return allSanityFaq;
};

export default useFAQs;
