import { useContext } from "react";
import { ShopifyContext } from "~context/shopify-context.jsx";

export const useShopify = () => {
  const shopify = useContext(ShopifyContext);

  return shopify;
};

export default useShopify;
