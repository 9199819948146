/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { globalHistory } from "@reach/router";

export const AppContext = createContext({});

/** ============================================================================
 * @context
 * Global application data.
 */
const AppProvider = ({ children }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [cookies, setCookie] = useCookies(null);

  const [cookiesActive, setCookiesActive] = useState(null);
  const [pathname, setPathname] = useState(null);
  const [menuActive, setMenuActive] = useState(false);
  const [reviewOverlayActive, setReviewOverlayActive] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window !== `undefined` && window?.location?.pathname) {
      setPathname(window.location.pathname);
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  useEffect(() => {
    if (!cookies?.accepted) {
      setCookiesActive(true);
    }
  }, [cookies]);

  useEffect(() => {
    if (cookiesActive === false) {
      setCookie(`accepted`, true, {
        path: `/`,
        sameSite: `lax`
      });
    }
  }, [cookiesActive]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <AppContext.Provider
      value={{
        cookiesActive,
        setCookiesActive,
        pathname,
        menuActive,
        setMenuActive,
        reviewOverlayActive,
        setReviewOverlayActive
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
