import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import getSymbolFromCurrency from "currency-symbol-map";
import { useKeyPress } from "~hooks";
import { spliceFromStateArrayByIndex } from "~utils/helpers";

export const ShopifyContext = createContext({});
export const ShopifyContextConsumer = ShopifyContext.Consumer;

/** ============================================================================
 * @component
 */
const ShopifyProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(null);

  const [activeCurrency, setActiveCurrency] = useState(`USD`);
  const [activeCurrencySymbol, setActiveCurrencySymbol] = useState(`$`);
  const [cart, setCart] = useState([]);
  const [cartActive, setCartActive] = useState(false);
  const [inventory, setInventory] = useState({});
  const [inventoryFetched, setInventoryFetched] = useState(false);

  // ---------------------------------------------------------------------------
  // methods (client)

  /**
   * -----------------------------------------------------------------------------
   * Find a Sanity Product by passing the useSanityShopifyProduct data with a
   * variant ID. Returns the full variants array and an extra field called
   * 'variant' matching the ID originally passed.
   * @param  {variantId}                 string  Needle
   * @param  {allSanityShopifyProduct}   object  Haystack
   * @return {product}                   object  sanityShopifyProduct
   */
  const getProductByVID = (variantId, allSanityShopifyProduct) => {
    if (!allSanityShopifyProduct?.edges?.[0] || !variantId) {
      return null;
    }

    const productNode = allSanityShopifyProduct.edges.find(({ node }) =>
      node.variants.some((variant) => variant?.sourceData?.id === variantId)
    );

    if (!productNode || !productNode?.node?.variants?.[0]?.sourceData) {
      return productNode;
    }

    const product = JSON.parse(JSON.stringify(productNode.node));

    product.variant = product.variants.find(
      ({ sourceData }) => sourceData?.id === variantId
    );
    return product;
  };

  const getCartProduct = (sanityProduct, userSelectedOptions) => {
    const userSelectedOptionKeys = Object.keys(userSelectedOptions);
    const scoreThreshold = userSelectedOptionKeys.length;

    let cartProduct = null;

    if (!sanityProduct?.variants?.[0]) {
      return cartProduct;
    }

    sanityProduct.variants.forEach((variant) => {
      if (cartProduct || !variant?.sourceData?.selectedOptions?.[0]) {
        return;
      }

      let score = 0;

      variant.sourceData.selectedOptions.forEach((variantOption) => {
        if (cartProduct) {
          return;
        }

        userSelectedOptionKeys.forEach((userSelectedOptionKey) => {
          if (cartProduct) {
            return;
          }

          const userSelectedOption = userSelectedOptions[userSelectedOptionKey];

          if (
            variantOption?.name?.toLowerCase() ===
              userSelectedOptionKey.toLowerCase() &&
            variantOption?.value?.toLowerCase() ===
              userSelectedOption.toLowerCase()
          ) {
            score += 1;
          }
        });
      });

      if (score >= scoreThreshold) {
        let variantGallery = null;

        if (sanityProduct?.options?.[0]) {
          sanityProduct.options.forEach(({ name, values }) => {
            if (
              variantGallery ||
              name.toLowerCase() !== `color` ||
              !values?.[0]
            ) {
              return;
            }

            values.forEach(({ value, gallery }) => {
              if (variantGallery || !value || !gallery?.[0]) {
                return;
              }

              variant.sourceData.selectedOptions.forEach((variantOption) => {
                if (
                  variantGallery ||
                  variantOption?.name?.toLowerCase() !== `color`
                ) {
                  return;
                }

                if (
                  variantOption?.value?.toLowerCase() === value.toLowerCase()
                ) {
                  variantGallery = gallery;
                }
              });
            });
          });
        }

        cartProduct = {
          ...sanityProduct,
          variant,
          variantGallery,
          variants: null
        };
      }
    });

    return cartProduct;
  };

  const getPrice = (variant) => {
    let price;

    if (!variant?.presentmentPrices?.edges?.[0]) {
      ({ price } = variant);
    } else {
      variant.presentmentPrices.edges.forEach(({ node }) => {
        if (price) {
          return;
        }

        if (
          node?.price?.currencyCode === activeCurrency &&
          node?.price?.amount
        ) {
          price = node.price.amount;
        }
      });
    }

    return price || null;
  };

  // ---------------------------------------------------------------------------
  // methods (Shopify)

  const addToCart = (variantId, quantity = 1) => {
    let existingCartPosition = null;

    const cartClone = JSON.parse(JSON.stringify(cart));

    cartClone.forEach((cartItem, cartIndex) => {
      if (existingCartPosition !== null) {
        return;
      }

      if (cartItem.variantId === variantId) {
        existingCartPosition = cartIndex;
      }
    });

    if (existingCartPosition === null) {
      cartClone.push({
        quantity,
        variantId
      });
    } else {
      cartClone[existingCartPosition].quantity += quantity;
    }

    //

    setCartActive(true);
    setCart(cartClone);
  };

  const checkout = () => {
    if (
      !process.env.GATSBY_SHOPIFY_STORE ||
      process.env.GATSBY_SHOPIFY_STORE === ``
    ) {
      // eslint-disable-next-line no-console
      console.error(`Shopify environment variables have not been defined.`);

      return;
    }

    const pIds = [];

    const getCheckoutURL = async () => {
      let lineItemsString = `[`;

      cart.forEach((cartItem, cartItemIndex) => {
        let prefix = ``;
        pIds.push(cartItem?.variantId);

        if (cartItemIndex !== 0) {
          prefix = `, `;
        }

        lineItemsString += `${prefix}{variantId: "${cartItem.variantId.replace(
          `Shopify__ProductVariant__`,
          ``
        )}", quantity: ${cartItem.quantity}}`;
      });

      lineItemsString += `]`;

      const data = {
        query: `
          mutation {
            checkoutCreate(
              input: {
                lineItems: ${lineItemsString},
                presentmentCurrencyCode: ${activeCurrency}
              }
            ) {
              checkout {
                id
                webUrl
              }
            }
          }
        `
      };

      const response = await fetch(`/api/shopify-storefront-graphql`, {
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": `application/json`
        }),
        method: `POST`
      });

      return response;
    };

    // window.fbq(`track`, `Purchase`, {
    //   content_ids: pIds,
    //   content_type: `product`,
    //   currency: activeCurrency
    // });

    getCheckoutURL().then((response) => {
      response.json().then(({ body }) => {
        if (body?.data?.checkoutCreate?.checkout?.webUrl) {
          //
          // data is the Shopify checkout object

          let { webUrl } = body.data.checkoutCreate.checkout;

          window.fbq(`track`, `InitiateCheckout`);

          // todo: watch canonical URLs (slashes)
          if (
            webUrl?.includes(
              `${process.env.GATSBY_SHOPIFY_STORE}.myshopify.com`
            )
          ) {
            webUrl = webUrl.replace(
              `${process.env.GATSBY_SHOPIFY_STORE}.myshopify.com`,
              `checkout.selfcareoriginals.com`
            );
          }

          window.location.href = webUrl;
        }
      });
    });
  };

  const decreaseQuantityByCartIndex = (cartIndex) => {
    if (!cart?.[cartIndex]) {
      return;
    }

    const cartClone = JSON.parse(JSON.stringify(cart));

    if (cartClone[cartIndex].quantity <= 1) {
      cartClone.splice(cartIndex, 1);
    } else {
      cartClone[cartIndex].quantity -= 1;
    }

    setCart(cartClone);
  };

  const increaseQuantityByCartIndex = (cartIndex) => {
    if (!cart?.[cartIndex]) {
      return;
    }

    const cartClone = JSON.parse(JSON.stringify(cart));

    cartClone[cartIndex].quantity += 1;

    setCart(cartClone);
  };

  const refreshInventory = () => {
    if (typeof window === `undefined`) {
      return;
    }

    const getAvailableForSale = async () => {
      const data = {
        query: `
          query {
            products(first: 250) {
              edges {
                node {
                  variants(first: 100) {
                    edges {
                      node {
                        id
                        availableForSale
                      }
                    }
                  }
                }
              }
            }
          }
        `
      };

      const response = await fetch(`/api/shopify-storefront-graphql`, {
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": `application/json`
        }),
        method: `POST`
      });

      return response;
    };

    getAvailableForSale().then((response) => {
      response.json().then(({ body }) => {
        const availableInventory = {};

        if (!body?.data?.products?.edges?.[0]) {
          setInventory({});
          return;
        }

        body.data.products.edges.forEach(({ node }) => {
          if (!node?.variants?.edges?.[0]) {
            return;
          }

          node.variants.edges.forEach((variantNode) => {
            const variant = variantNode.node;

            availableInventory[variant?.id] = variant.availableForSale;
          });
        });

        setInventory(availableInventory);
      });
    });
  };

  const removeFromCartByIndex = (cartIndex) => {
    if (!cart?.[cartIndex]) {
      return;
    }

    setCart(spliceFromStateArrayByIndex(cart, cartIndex));
  };

  const setQuantityByCartIndex = (cartIndex, quantity) => {
    if (!cart?.[cartIndex]) {
      return;
    }

    const cartClone = JSON.parse(JSON.stringify(cart));

    cartClone[cartIndex].quantity = quantity;

    setCart(cartClone);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  const escPressed = useKeyPress(`Escape`);

  useEffect(() => {
    setCartActive(false);
  }, [escPressed]);

  /**
   * ---------------------------------------------------------------------------
   * useEffect []
   * Load data from cookies on init.
   */
  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    // cart

    if (cookies?.[`${process.env.GATSBY_REGION_CODE}_cart`]) {
      const parsedCart = cookies[`${process.env.GATSBY_REGION_CODE}_cart`];

      let valid =
        Array.isArray(cookies[`${process.env.GATSBY_REGION_CODE}_cart`]) &&
        cookies?.[`${process.env.GATSBY_REGION_CODE}_cart`]?.[0];

      if (valid) {
        parsedCart.forEach((cookieCartItem) => {
          if (!valid) {
            return;
          }

          if (
            typeof cookieCartItem === `undefined` ||
            cookieCartItem === null ||
            cookieCartItem === false ||
            cookieCartItem === `` ||
            !cookieCartItem?.variantId ||
            !cookieCartItem?.quantity
          ) {
            valid = false;
          }
        });
      }

      if (!valid || process.env.GATSBY_RESET_COOKIES) {
        setCart([]);
        setCookie([`${process.env.GATSBY_REGION_CODE}_cart`], [], {
          path: `/`
        });
      } else {
        setCart(parsedCart);
      }
    }

    // currency

    setActiveCurrency(cookies?.currency || `USD`);

    refreshInventory();
  }, []);

  /**
   * ---------------------------------------------------------------------------
   * useEffect [activeCurrency]
   * Automatically store the currency symbol whenever the user's currency
   * is changed.
   */
  useEffect(() => {
    if (activeCurrency) {
      setActiveCurrencySymbol(getSymbolFromCurrency(activeCurrency));
    }
  }, [activeCurrency]);

  /**
   * ---------------------------------------------------------------------------
   * useEffect [cart]
   * Update the cart cookie whenever it changes.
   */
  useEffect(() => {
    let cookieCart = [];

    if (typeof cart !== `undefined` && cart !== null) {
      cookieCart = cart;
    }

    setCookie([`${process.env.GATSBY_REGION_CODE}_cart`], cookieCart, {
      path: `/`
    });
  }, [cart]);

  /**
   * ---------------------------------------------------------------------------
   * useEffect [inventory]
   * Mark inventory as fetched on update.
   */
  useEffect(() => {
    if (
      typeof inventory !== `undefined` &&
      inventory !== null &&
      !inventoryFetched
    ) {
      setInventoryFetched(true);
    }
  }, [inventory]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <ShopifyContext.Provider
      value={{
        cart,
        setCart,
        cartActive,
        setCartActive,
        activeCurrencySymbol,
        setActiveCurrencySymbol,
        activeCurrency,
        setActiveCurrency,
        inventory,
        setInventory,
        refreshInventory,
        inventoryFetched,
        setInventoryFetched,
        //
        getProductByVID,
        getCartProduct,
        getPrice,
        //
        addToCart,
        checkout,
        decreaseQuantityByCartIndex,
        increaseQuantityByCartIndex,
        setQuantityByCartIndex,
        removeFromCartByIndex
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

ShopifyProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ShopifyProvider;
