import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { useKeyPress } from "~hooks";

export const NewsletterContext = createContext({});

const NewsletterProvider = ({ children }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [newsletterActive, setNewsletterActive] = useState(false);
  const [cookies, setCookie] = useCookies([`newsletter-seen`]);

  // ---------------------------------------------------------------------------
  // lifecycle

  const escPressed = useKeyPress(`Escape`);

  useEffect(() => {
    setNewsletterActive(false);
  }, [escPressed]);

  useEffect(() => {
    if (
      typeof window === `undefined` ||
      cookies?.[`newsletter-seen`]?.toString() === `1`
    ) {
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      setNewsletterActive(true);
    }, 1750);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [cookies]);

  useEffect(() => {
    if (newsletterActive) {
      setCookie(`newsletter-seen`, `1`, { path: `/` });
    }
  }, [newsletterActive]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <NewsletterContext.Provider
      value={{
        newsletterActive,
        setNewsletterActive
      }}
    >
      {children}
    </NewsletterContext.Provider>
  );
};

NewsletterProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NewsletterProvider;
