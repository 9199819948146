import { useStaticQuery, graphql } from "gatsby";

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            description
            fbAppId
            keywords
            image
            title
            titleTemplate
            twitterUsername
            siteUrl
          }
        }
      }
    `
  );

  return site.siteMetadata;
};

export default useSiteMetadata;
